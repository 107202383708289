import { Modal, Button, Form } from 'react-bootstrap'
import { useState } from 'react'
import axios from 'axios';
import { propTypes } from 'react-bootstrap/esm/Image';

function MakePost(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Send the post to Cloudflare KV
    const sendPost = event => {
        event.preventDefault();
        const { title, content, imageFile } = event.target;


        console.log(title.value, content.value, imageFile)
        var reader = new FileReader();
        var preview = new Image();

        var imageData = "";

        var file = imageFile.files[0];
        console.log("file is", file, imageFile)

        // If image uploaded
        if (file === undefined) {

            axios.post(
                'https://workers.leungjch-s22.workers.dev/posts',
                {
                    title: title.value,
                    content: content.value,
                    username: props.username,
                    imageData: "",
                    timestamp: new Date().getTime()
                },
                {
                    withCredentials: true
                }
                ).then((response) => {
                    console.log("resp is", response)
                    handleClose();
                }).catch((error) => {
                    console.log(error)
                    if (error.response)
                     {
                        if (error.response.status == 400) 
                        {
                            alert("Another person already used your username. Please set a new username.")
                        }
                        else 
                        {
                            alert("Error creating post. Most likely the authentication server (from Systems assignment) is down.")
                        }
    
                     }
                })
            
        }
        else {
            var reader = new FileReader();
            reader.onloadend = function () {
                imageData = reader.result;
    
                axios.post(
                    'https://workers.leungjch-s22.workers.dev/posts',
                    // 'http://127.0.0.1:8787/post',

                    {
                        title: title.value,
                        content: content.value,
                        username: props.username,
                        imageData: imageData,
                        timestamp: new Date().getTime()
                    },
            
                {
                    withCredentials: true
                }
                ).then((response) => {
                    console.log("resp is", response)
                    handleClose();
                }).catch((error) => {
                    if (error.response)
                     {
                        if (error.response.status == 400) 
                        {
                            alert("Another person already used your username. Please set a new username.")
                        }
                        else 
                        {
                            alert("Error creating post. Most likely the authentication server (from Systems assignment) is down.")
                        }
    
                     }
                })
    
            }
            reader.readAsDataURL(file);
    
        }

    }

    return (
        <>
            <Button variant="primary" style={{ "margin": "0.5em" }} onClick={handleShow}>
                Create a post!
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a post!</Modal.Title>
                </Modal.Header>
                <Form onSubmit={sendPost}>

                    <Modal.Body>
                        <p>
                            Posting as: {props.username}
                        </p>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Title</Form.Label>
                            <Form.Control name="title" type="text" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Content</Form.Label>
                            <Form.Control name="content" as="textarea" rows={3} />
                        </Form.Group>
                        <Form.Group controlId="formFile" className="mb-3" >
                            <Form.Label>Image (optional)</Form.Label>
                            <Form.Control name="imageFile" type="file" accept="image/*" />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    );
}

export default MakePost