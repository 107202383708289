import { Modal, Button, ListGroup, Card } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import axios from 'axios'
import missingImgIcon from '../missing-img.png'
function ListPosts() {
  const [posts, setPosts] = useState([
    {
      "title": "Lorem Post",
      "username": "Guest",
      "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.       ",
      "imageData": "",
      "timestamp": 1640992542111,
    }
  ]);

  useEffect(() => {
    function fetchPosts() {
      // Update the document title using the browser API
      axios({
        method: 'GET',
        url: 'https://workers.leungjch-s22.workers.dev/posts',

      }).then((response) => {
        console.log(response.data)
        setPosts(response.data)
      }, (error) => {
        console.log(error);
      });

    }
    fetchPosts()
    setInterval(fetchPosts, 5*1000);
  }, []);

  return (
    <>

      <ListGroup>
        {
          posts.sort(function(a, b) {
            var dateA = new Date(a.timestamp);
            var dateB = new Date(b.timestamp);
          
            if (dateA < dateB ) {
              return 1;
            }
            if (dateA > dateB ) {
              return -1;
            }
            return 0;
          }).map((item, index) => {
            return <div style={{ "margin": "0.5em", marginLeft: "1em", marginRight: "1em", "display": "flex", "flex": "1 1 auto", borderRadius: "12px" }}>
              <img style={{ width: "250px", height: "250px" }} variant="left" src={item["imageData"] !== undefined && item.imageData !== "" ? item.imageData : missingImgIcon} />
              <Card style={{ "width": "100%" }}>

                <Card.Header as="h5">{item.title}</Card.Header>
                <Card.Body>
                  {/* <Card.Title>Posted by {item.username}</Card.Title> */}
                  <Card.Text>
                    {item.content}
                  </Card.Text>
                  <Card.Text style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  }}>
                    Posted by {item.username}
                    <br />
                    Date Posted: {new Date(item.timestamp).toLocaleString()}
                  </Card.Text>
                </Card.Body>
              </Card>

            </div>
          })
        }

      </ListGroup>
    </>
  );
}

export default ListPosts