import logo from './logo.png';
import MakePost from './components/MakePost.js'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ListPosts from './components/ListPosts';
import { Navbar, Container, Card, Button, Modal, Form } from 'react-bootstrap'
import { useState, setState } from 'react'
function App() {
  const [username, setUsername] = useState(makeid(5))
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitUsername = event => {
    event.preventDefault();
    const { username } = event.target;


    setUsername(username.value)
    handleClose()
  }

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }


  return (
    <div className="App">
      <header className="App-header">
        <Navbar bg="light" variant="light">
          <Navbar.Brand >
            <img
              alt=""
              src={logo}
              width="65px"
              height="auto"
              className="d-inline-block align-top"
            />{' '}
            Cloudflareddit
          </Navbar.Brand>
          <div>
            <MakePost username={username} />
          </div>
          <>
            <Button variant="outline-primary" style={{ "margin": "0.5em"}} onClick={handleShow}>
              Set Username
            </Button>

            <Button variant="outline-danger">
              Logged in as: {username}
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Set your username</Modal.Title>
              </Modal.Header>
              <Form onSubmit={submitUsername}>
                <Modal.Body>
                  <p>Note: Following the Extra Credit specifications in the Systems assignment, username validation is performed only after you create a post.</p>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Username</Form.Label>
                    <Form.Control name="username" type="text" placeholder="" />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>

            </Modal>
          </>
        </Navbar>





        <ListPosts />

      </header>
    </div>
  );
}

export default App;
